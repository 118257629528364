import { isArray } from 'lodash-es'
import { isCraftEmbed, isCraftImage, isCraftVideo } from './craft'
import type { EmbedAsset } from '@/graphql/embed'
import type { ImageAsset } from '@/graphql/image'
import type { VideoAsset } from '@/graphql/video'
import type { MaybeArray } from '@/types/maybe-array'

export interface Thumbnail {
	id: string
	type: string
	url: string
	alt?: string
	title?: string
	caption?: string
	width?: number
	height?: number
}

const PLACEHOLDER: Thumbnail = {
	id: '-1',
	type: 'video',
	url: '/images/placeholder.jpg',
	alt: 'Placeholder image',
	width: 580,
	height: 435,
}

export function getThumbnails(items: any): Thumbnail[] {
	items = isArray(items) ? items : [items]
	return items.map((item: any) => getThumbnail(item))
}

export function getThumbnail(item: any) {
	if (isCraftEmbed(item)) return getEmbedThumbnail(item)
	else if (isCraftImage(item)) return getImageThumbnail(item)
	else if (isCraftVideo(item)) return getVideoThumbnail(item)
	else return PLACEHOLDER
}

export function getEmbedThumbnail(item: MaybeArray<EmbedAsset>) {
	const embed = isArray(item) ? item[0] : item

	if (embed?.thumbnail?.length) {
		return <Thumbnail>{
			id: embed.id,
			type: 'video',
			provider: 'craft',
			url: embed.thumbnail?.[0]?.url,
			alt: embed.alt ?? embed.title,
			title: embed.title,
			caption: embed.caption,
			width: embed.thumbnail?.[0]?.width,
			height: embed.thumbnail?.[0]?.height,
		}
	}

	if (embed?.oEmbed?.image) {
		let url = embed.oEmbed.image

		// Replace YouTube's default thumbnail with the max resolution thumbnail
		if (url.includes('hqdefault')) {
			url = url.replace('hqdefault', 'maxresdefault')
		}

		return <Thumbnail>{
			id: embed.id,
			type: 'video',
			provider: 'ipx',
			url,
			alt: embed.alt ?? embed.title,
			title: embed.title,
			caption: embed.caption,
			width: embed.oEmbed.width,
			height: embed.oEmbed.height,
		}
	}

	return PLACEHOLDER
}

export function getImageThumbnail(item: MaybeArray<ImageAsset>) {
	const img = isArray(item) ? item[0] : item

	return img
		? <Thumbnail>{
				id: img.id,
				type: 'image',
				provider: 'craft',
				url: img.url,
				alt: img.alt ?? img.title,
				title: img.title,
				caption: img.caption,
				width: img.width,
				height: img.height,
			}
		: PLACEHOLDER
}

export function getVideoThumbnail(item: MaybeArray<VideoAsset>) {
	const video = isArray(item) ? item[0] : item

	return video?.thumbnail?.length
		? <Thumbnail>{
				id: video.id,
				type: 'video',
				provider: 'craft',
				url: video.thumbnail?.[0]?.url,
				alt: video.alt,
				title: video.title,
				caption: video.caption,
				width: video.thumbnail?.[0]?.width,
				height: video.thumbnail?.[0]?.height,
			}
		: PLACEHOLDER
}
